(function ($) {
  Drupal.behaviors.mppFilters = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $trigger = $('.js-mpp-filter-trigger');

      $trigger.on('click', function (e) {
        e.preventDefault();
        var filterBy = $(this).data('filter-by');

        if (!filterBy || !filterBy.length) {
          return null;
        }
        var $filterMenu = $('.js-mpp-filter-menu[data-filter-by=' + filterBy + ']');
        var fieldName = $filterMenu.val();

        $(document).trigger('mpp_filter:selected', [filterBy, fieldName]);
      });
    },
    attached: false
  };
})(jQuery);
